import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { useState, useContext} from 'react';


import style from './style.scss';
import { LoginContext } from './../../contexto/index';
import useDatabase from '../../hooks/useDatabase';



export default function Login(props:any){
    const pass: string = "thebestsnake22";
    const [login2,setLogin] = useState<string>("");
    const [input,setInput] = useState<string>("");
    const login = useContext(LoginContext);
    

    function boton(){
        setLogin(input);
        console.log(login2);
    }
    function change(e: React.FormEvent<HTMLInputElement>){
        setInput(e.currentTarget.value);
    }
    if (login2 == pass){
        login.setLogeado();
        return <Redirect to="/inicio" />
    } else {
        return (
            <div className={style.login}>
                <div className={style.wrapper}>
                    <h2>Oxigeno crosstraining</h2>
                    <input type="password" value={input} onChange={change} placeholder='Contraseña' />
                    <button onClick={boton}>Ingresar</button>
                </div>
            </div>
        );
    }
}