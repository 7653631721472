import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { useState, useEffect} from 'react';

import style from './style.scss';
import logo from '../../assets/logo.png'

export default function Menu(props:any){
    return (
        <div className={style.menu}>
            <nav className={style.lista}>
                <NavLink exact={true} activeClassName={style.activo} to="/inicio">
                <i className="fas fa-home"></i><p>Inicio</p></NavLink>
                <NavLink activeClassName={style.activo} to="/clientes"><i className="fas fa-users"></i><p>Clientes</p></NavLink>
                <NavLink activeClassName={style.activo} to="/clases"><i className="fas fa-dumbbell"></i><p>Clases</p></NavLink>
                <NavLink activeClassName={style.activo} to="/cuotas"><i className="fas fa-stream"></i><p>Cuotas</p></NavLink>
            </nav>
        </div>
    );
}